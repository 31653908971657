import UserExperior, { UserExperiorOptions } from "./app/index";
import { getSessionUrl } from "./lib/getMetadata";
import { IN_BROWSER, MASK_ALL_INPUTS, DEFAULT_MASK_INPUT_OPTIONS } from "./utils";
import { CONSOLE_LOG_TYPES, ConsoleLogTypes } from "./types";

const DEFAULT_CAPTURE_LOG_TYPES = [CONSOLE_LOG_TYPES.WARN, CONSOLE_LOG_TYPES.ERROR];

export default class App {
    private app: UserExperior | null = null;
    public sessionUrl: string;
    readonly UE_NOT_INITIALIZED = 'UserExperior is not initialized. Please check documentation for more information.';

    private defaultOptions: UserExperiorOptions = {
        sessionReplay: {
            maskAllInputs: MASK_ALL_INPUTS,
            maskInputOptions: DEFAULT_MASK_INPUT_OPTIONS,
            recordCrossOriginIframes: true,
            captureConsoleLogTypes: DEFAULT_CAPTURE_LOG_TYPES,
        }
    }

    constructor() {
        this.sessionUrl = getSessionUrl();
    }

    startRecording(projectKey: string, options: UserExperiorOptions = this.defaultOptions, releaseVersion:string | null = null ){
        if (!IN_BROWSER) {
            // checking weather the code is executed in browser or not
            console.error('UserExperior: You are trying to start UserExperior on a node.js environment.')
            return Promise.reject("UserExperior: You are trying to start UserExperior on a node.js environment.");
        }
        if (!projectKey || typeof projectKey !== 'string') {
            // checking the passed projectKey value is string.
            console.error('UserExperior: version-key is missing or wrong (string is expected). Please check documentation for more information.')
            return Promise.reject("UserExperior: version-key is missing or wrong (string is expected). Please check documentation for more information.");
        }

        if (options === null || options.constructor !== Object) {
            // checking the passed options value is object.
            console.error('UserExperior: options is wrong (object is expected). Please check documentation for more information.')
            return Promise.reject("UserExperior: options is wrong (object is expected). Please check documentation for more information.");
        }

        if (Object.keys(options).includes('sessionReplay')) {
            // checking the passed options has sessionReplay key:
            // if true, union the options.sessionReplay with defaultOption.sessionReplay values.
            options.sessionReplay = { ...this.defaultOptions.sessionReplay, ...options.sessionReplay }
        } else {
            // if false, union the options with defaultoption values.
            options = { ...this.defaultOptions, ...options }
        }
       
        if (!(typeof releaseVersion === 'string' || releaseVersion === null)) {
            console.error('UserExperior: release version is wrong (string is expected). Please check documentation for more information.')
            return Promise.reject("UserExperior: release version is wrong (string is expected).Please check documentation for more information.");
        } 

        if (options.sessionReplay.captureConsoleLogTypes?.length) {
            const validLogTypes: ConsoleLogTypes[] = []
            const invalidTypes: string[] = []
            for (const logtype of options.sessionReplay.captureConsoleLogTypes) {
                if (this.isValidLogType(logtype)) {
                    options.sessionReplay.captureConsoleLogTypes = DEFAULT_CAPTURE_LOG_TYPES;
                    validLogTypes.push(logtype);
                } else {
                    invalidTypes.push(logtype);
                    
                }
            }
            const allowedTypes = Object.values(CONSOLE_LOG_TYPES).join(', ');
            if (invalidTypes.length) {
                console.warn(`Invalid log type '${invalidTypes.join(', ')}' passed to captureConsoleLogTypes. Allowed types are: ${allowedTypes}`);
            }
            options.sessionReplay.captureConsoleLogTypes = validLogTypes;
        }

        this.app = new UserExperior(projectKey, options, releaseVersion);

       
        return this.app.startRecording();
    }

    setUserIdentifier(id: string, userProperties?: object): void {
        if (id && typeof id === 'string' && this.app !== null) {
            // checking the app is initialized and the passing value is string.
            this.app.setUserIdentifier(id, userProperties);
        } else {
            console.error(!this.app ?
                this.UE_NOT_INITIALIZED :
                'UserExperior: user id is missing or wrong type (string is expected). Please check documentation for more information.')
        }
    }

    addMetaData(metadata: object): void {
        if (this.app !== null && metadata != null && metadata.constructor === Object) {
            this.app.addMetaData(metadata);
        } else {
            console.error(!this.app ?
                this.UE_NOT_INITIALIZED :
                'UserExperior: metadata is missing or wrong type (object is expected). Please check documentation for more information.');
        }
    }

    setUserProperties(userTraits: object): void {
        if (this.app !== null && userTraits && userTraits !== null && userTraits.constructor === Object) {
            // checking the app is initialized and the passing value is object.
            this.app.setUserProperties(userTraits);
        } else {
            console.error(!this.app ?
                this.UE_NOT_INITIALIZED :
                'UserExperior: user properties is missing or wrong type (object is expected). Please check documentation for more information.')
        }
    }

    logEvent(event: string, eventDetails?: object): void {
        if (this.app !== null && event && typeof event === 'string') {
            // checking the app is initialized and the adding the log event.
            if (eventDetails === null || (eventDetails !== undefined && eventDetails.constructor !== Object)) {
                console.error('UserExperior: event props is wrong type (object is expected). Please check documentation for more information.')
            } else {
                this.app.logEvent(event, eventDetails);
            }
        } else {
            console.error(!this.app ?
                this.UE_NOT_INITIALIZED :
                'UserExperior: log event is missing or wrong type (string is expected). Please check documentation for more information.')
        }
    }

    getSessionDetails(): any { 
        if (this.app !== null) {
            return this.app.getSessionDetails();
        }
    }

    unsetUserIdentifier(): void {
        if (this.app !== null) {
            this.app.unsetUserIdentifier()
        }
    }

    restartSession(): void {
        if (this.app !== null) {
            this.app.restartSession(true)
        }
    }

    getNormalizedUrl(): string {
        const url = this.getNormalizedUrl();
        return url;
    }

    isValidLogType(logType: unknown): logType is ConsoleLogTypes {
        return Object.values(CONSOLE_LOG_TYPES).includes(logType as ConsoleLogTypes);
    }

    stopRecording(): void{
        if (this.app !== null) {
            return this.app.triggerStopRecording();
        }
    }
}

export const init = App;