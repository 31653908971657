import bowser from "../../bowser"
import { getPerformanceData } from "./collectPerformance";
import { getNormalizedUrl } from "../utils";

interface BrowserDataInterface {
    browserName?: string,
    os?: string,
    navigatorInfo?: Navigator,
    locale?: string,
}

const tpid: string = (Math.random() + 1).toString(36).substring(4);

export interface objectStringAccess {
    [key: string]: string | null
}

// const getLocalStorage = (): object => {
//     const localStorageObject: objectStringAccess = {};
//     for (const key in localStorage) {
//         localStorageObject[key] = localStorage.getItem(key);
//     }
//     return localStorageObject;
// }

const getBowserData = (): bowser.Parser.ParsedResult => {
    return bowser.parse(window.navigator.userAgent)
}

const getMiscBrowserData = (): object => {
    const nVer: string = navigator.appVersion;

    const currentBrowserData: BrowserDataInterface = {
        browserName: "Unknown"
    };

    // browserName = nVer.match(/(firefox|msie|chrome|safari)[/\s]([\d.]+)/ig)[0];
    const browserNameList = nVer.match(/(firefox|msie|chrome|safari)[/\s]([\d.]+)/gi)
    if (browserNameList) {
        currentBrowserData.browserName = browserNameList[0];
    }

    let OSName = "Unknown OS";
    if (nVer.indexOf("Win") !== -1) OSName = "Windows";
    if (nVer.indexOf("Mac") !== -1) OSName = "MacOS";
    if (nVer.indexOf("X11") !== -1) OSName = "UNIX";
    if (nVer.indexOf("Linux") !== -1) OSName = "Linux";

    currentBrowserData.os = OSName;
    currentBrowserData.navigatorInfo = navigator;
    currentBrowserData.locale = navigator.language;

    return currentBrowserData;
}

const getNavigator = (): object => {
    const validNavigatorKeys: string[] = [
        "productSub",
        "vendor",
        "cookieEnabled",
        "appVersion",
        "platform",
        "userAgent",
    ];
    const navigatorObject: any = {};
    let navKey: keyof Navigator;

    for (navKey in navigator) {
        if (validNavigatorKeys.indexOf(navKey) >= 0) {
            navigatorObject[navKey] = navigator[navKey];
        }
    }
    return navigatorObject;
}

export const getSessionUrl = (): string => {
    return "https://webmonitoring.userexperior.online/e/"+tpid;
}

let performanceData: any;
function getConnectionDetails() { 
  if ('connection' in navigator) { 
    const networkInfo = navigator.connection as any;
    if (networkInfo) {
      const effectiveType = networkInfo.effectiveType;
      return effectiveType;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

function getDeviceModel() {
    const userAgent = navigator.userAgent;
    // Check for Android-based user agents
    const androidRegex = /Android\s[\d\.]+;\s?([^)]*)\)/i;
    const androidMatch = userAgent.match(androidRegex);
    if (androidMatch && androidMatch[1]) {
        const deviceInfo = androidMatch[1].trim();
        const model = deviceInfo.replace(/\s*Build.*$/, '').split(";").pop()?.trim() || '';
        return model || undefined;
    }

    // Check for BlackBerry (BB10) user agents
    const blackberryRegex = /\(BB10;\s([^)]+)\)/i;
    const blackberryMatch = userAgent.match(blackberryRegex);
    if (blackberryMatch && blackberryMatch[1]) {
        return blackberryMatch[1].trim();
    }
    // Check for BlackBerry PlayBook user agents
    const playbookRegex = /\(PlayBook;[^)]+\)/i;
    const playbookMatch = userAgent.match(playbookRegex);
    if (playbookMatch) {
        return "PlayBook";
    }

    // Check for iOS devices (iPhone, iPad)
    const iosRegex = /\((iPhone|iPad).*?\)/i;
    const iosMatch = userAgent.match(iosRegex);
    if (iosMatch && iosMatch[1]) {
        return iosMatch[1]; // Return "iPhone" or "iPad"
    }

    return undefined; // Default case
}


export const getBrowserData = (): object => {
    const miscdata: BrowserDataInterface = getMiscBrowserData();
    const pageTitleElement = document.getElementsByTagName("title")
    performanceData = getPerformanceData();

    return {
        page_title: pageTitleElement.length ? pageTitleElement[0].innerHTML : document.title,
        // local_storage: getLocalStorage(),
        browser_data: getNavigator(),
        url: window.location.href.split('?')[0],
        normalizedUrl: getNormalizedUrl(window.location.href.split('?')[0]),
        browser: miscdata.browserName,
        bowser_data: getBowserData(),
        os: miscdata.os,
        locale: navigator.language,
        viewport: `${window.innerWidth}x${window.innerHeight}`,
        screen_size: `${screen.width}x${screen.height}`,
        density: window.devicePixelRatio,
        tpId: tpid,
        performance: performanceData,
        networkType: getConnectionDetails(),
        deviceModel: getDeviceModel(),
    };
}
