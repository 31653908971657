import { DEFAULT_BASE_URL, INITILIZE_SESSION_PATH, INITILIZE_USER_PATH, SEND_CUSTOM_METADATA, SEND_LOGS_PATH } from '../utils';
import { consoleLogs, resetConsoleData } from '../lib/collectLog';
import { networkLogs, resetNetworkData } from '../lib/collectNetwork';
import { eventLogs, resetEventData } from '../lib/collectEvents';
import {
    sessionEvents, resetSessionEvents,
    setLastUploadedTime, logEvents,
    resetLogEvents, sequenceNumber,
    increaseSequenceNumber,
} from './index'
import compression from '../lib/compression';

const DEFAULT_API_RETRY_COUNT = 3;

interface ApiInterface {
    createSession(data: object): void;
    sendUser(data: object): void;
    sendLogs(force: boolean, stopRecording: () => void): void;
    sendRequest(urlPath: string, data: object, method: string): void;
    sendCustomMetaData(data: object): void;
}

interface EventsPayload {
    domNavigationTimings?: object;
    sessionReplay: object[];
    consoleLogs: object[];
    networkLogs: object[];
    eventLogs: object[];
    events: object[];
    sequence: number,
    releaseVersion: string | null,
}

export default class Api implements ApiInterface {
    private readonly projectKey: string;
    private readonly sessionId: string;
    private readonly tabId: string;
    private readonly releaseVersion: string | null;
    private domNavigationTimingsSent: boolean;
    constructor(projectKey: string, sessionId: string, tabId: string, releaseVersion: string | null) {
        this.projectKey = projectKey
        this.sessionId = sessionId
        this.tabId = tabId
        this.releaseVersion = releaseVersion;
        this.domNavigationTimingsSent = false;
        this.domNavigationTimingsSent = false;
    }

    async createSession(data: object): Promise<boolean> {
        const status = await this.sendRequest(INITILIZE_SESSION_PATH, data);
        if (status === -1) {
            console.error('Session creation failed because API did not give 2xx response!');
            return false;  
        } else {
            return true;
        }
    }

    sendUser(data: object): void {
        this.sendRequest(INITILIZE_USER_PATH, {...data, releaseVersion: this.releaseVersion});
    }

    sendCustomMetaData(data: object): void {
        this.sendRequest(SEND_CUSTOM_METADATA, data);
    }

    sendLogs(force = false, stopRecording: () => void): void {

        let eventsData: EventsPayload = {
            sessionReplay: sessionEvents,
            consoleLogs: consoleLogs,
            networkLogs: networkLogs,
            eventLogs: eventLogs,
            events: logEvents,
            sequence: sequenceNumber,
            releaseVersion: this.releaseVersion,
        };

        if (document.readyState === "complete" && !this.domNavigationTimingsSent) {
            const entries = performance.getEntriesByType("navigation");
            entries.forEach((entry) => {
                eventsData = {
                    ...eventsData,
                    domNavigationTimings: entry.toJSON(),
                }
            });
        }

        const hasAnyData = eventsData.sessionReplay.length > 0 ||
            eventsData.consoleLogs.length > 0 ||
            eventsData.networkLogs.length > 0 ||
            eventsData.eventLogs.length > 0 ||
            eventsData.events.length > 0

        // check if the time elapsed is greater than the polling interval and 
        // if the size of the data is greater than threshold
        if (hasAnyData || force) {

            if (eventsData.hasOwnProperty('domNavigationTimings')) {
                this.domNavigationTimingsSent = true
            }

            //Changing the retryCount to 0 as it will overload the ingestion
            this.sendRequest(SEND_LOGS_PATH, eventsData, "POST", 0).then(responseCode => {
                if (responseCode === 401) {
                    // Stop recording.
                    stopRecording()
                    console.warn("UserExperior: the recording has been stopped, the version key is invalid or your session limit has reached.")
                }
            }).catch(error => {
                console.log(error)
            })

            setLastUploadedTime()
            resetSessionEvents()
            resetConsoleData()
            resetNetworkData()
            resetEventData()
            resetLogEvents()
            increaseSequenceNumber()
        }
    }

    async sendRequest(urlPath: string, data: object, method = "POST", retryCount = DEFAULT_API_RETRY_COUNT): Promise<number> {
        if (data === undefined || data === null || Object.keys(data).length === 0) {
            return -1;
        }

        // send request to server with the preflight timestamp
        let requestBody: any = {
            versionKey: this.projectKey,
            sessionId: this.sessionId,
            tabId: this.tabId,
            timestamp: Math.floor((new Date()).getTime()),
            releaseVersion: this.releaseVersion,
            ...data,
        }

        let headers: any = {
            'Content-Type': 'application/json',
            'ue-vk': this.projectKey,
            'ue-session-id': this.sessionId,
        }

        const compressedPayload = await compression(JSON.stringify(requestBody))
        if (compressedPayload.length > 0) {
            headers = {
                ...headers,
                'Content-Encoding': 'gzip'
            }
            requestBody = compressedPayload
        }

        try {
            if (!headers.hasOwnProperty('Content-Encoding')) {
                requestBody = JSON.stringify(requestBody)
            }
            const response = await fetchWithRetry(DEFAULT_BASE_URL + urlPath, {
                method: method,
                headers: headers,
                body: requestBody,
            }, retryCount)
            if(!response.ok) {
                return -1;
            }
            return response.status
        } catch (error) {
            console.log(error)
            return -1;
        }
    }
}

async function fetchWithRetry(url: string, options = {}, retries = 0, delay = 0) : Promise<Response> {
    try {
        const response = await fetch(url, options);

        // Check if the response status is OK (200-299)
        // Or if it is equal to 401 (Unauthorized), which means the version key is invalid
        if (!response.ok && response.status !== 401) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Return the response data
        return response;
    } catch (error) {
        if (retries > 0) {
            await new Promise(res => setTimeout(res, delay)); // Delay before retry
            return fetchWithRetry(url, options, retries - 1, delay);
        } else {
            throw error;
        }
    }
}
